import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";

const NavJob = () => {
	const data = useStaticQuery(graphql`
		query allJobs {
			allWordpressWpJobs {
				edges {
					node {
						id
						slug
						title
					}
				}
			}
		}
	`);

	const allJobs = data.allWordpressWpJobs.edges;

	return (
		<div className="navJob">
			<p className="nav-title">Stanowiska:</p>
			<ul>
				{allJobs.map((job) => (
					<li key={job.node.id}>
						<Link
							to={`/praca/${job.node.slug}`}
							activeClassName="navJob__link--active"
							dangerouslySetInnerHTML={{ __html: job.node.title }}
						></Link>
					</li>
				))}
			</ul>
		</div>
	);
};

export default NavJob;
