import React from "react";
import { Link } from "gatsby";
import { Layout, RecrutationForm, SubpageHeader } from "../components";
import NavJob from "../components/navJob";

const SingleJob = ({ pageContext }) => {
	const { title, desc, jobName } = pageContext.data;

	return (
		<Layout
			seo={{
				title: title.replace("&#038;", "&"),
				href: "pl",
				lang: "pl",
			}}
		>
			<SubpageHeader title={jobName} />
			<span className="anchor" id="home"></span>
			<section className="page_recrutation">
				<div className="row">
					<div className="col-md-3 single-offer-menu-container single-offer-menu-container--job">
						<Link to="/praca/">
							<div className="back-wrapper">
								<span className="back-wrapper__arrow"></span>
								<p className="back-wrapper__title">Praca</p>
							</div>
						</Link>
						<NavJob />
					</div>
					<div className="col-md-8 offset-md-1">
						<div
							className="panel-description"
							dangerouslySetInnerHTML={{ __html: desc }}
						/>
						<div className="contact-form-container panel-description">
							<RecrutationForm position={jobName || ""} />
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default SingleJob;
